const PlayerConstants  = {
    STG_HOSTNAMES : ['localhost','contentenablers.dev','alpha.rlm.contentenablers.com'],
    AUTH_HOST_STOREFRONT:['contentenablers.net'],
    VERSION:"V20.0",
    REDIRECT:{
        HREF:"https://contentenablers.com/",
        TIME: 2700000   //45min
    },
   ASSET_HOST:['thumbs.contentenablers.com','library.contentenablers.com','https://dfd4tx2w69ubk.cloudfront.net','https://d1oukasrldq7xd.cloudfront.net'],
    BETA_PATH:"https://beta-cached.contentenablers.com/api/",
    get API_DESTINATION_STAGING() {return (this.STG_HOSTNAMES.indexOf(window?.location?.hostname) !== -1 )? 'https://tradecompliance.training/xapice/api/' : 'https://api-cea.contentenablers.com/api/'},
    // get API_SCO_DESTINATION() {return (this.STG_HOSTNAMES.indexOf(window.location.hostname) !== -1) ? 'https://learner.contentenablers.com/storefront/api/playerv2/' : ( (window.ce?.platform_scorm?.apiReferrer === 'storefront') ? 'https://lms.contentenablers.com/storefront/api/playerv2/' : 'https://lms.contentenablers.com/celms/api/playerv2/')},
    // get API_SCO_DESTINATION() {return (this.STG_HOSTNAMES.indexOf(window.location.hostname) !== -1) ? ((window.ce?.platform_scorm?.apiReferrer === 'storefront') ? 'https://learner.contentenablers.com/storefront/api/playerv2/':'https://learner.contentenablers.com/celms/api/playerv2/') : ( (window.ce?.platform_scorm?.apiReferrer === 'storefront') ? 'https://lms.contentenablers.com/storefront/api/playerv2/' : 'https://lms.contentenablers.com/celms/api/playerv2/')},
    get API_SCO_DESTINATION() {return (this.STG_HOSTNAMES.indexOf(window?.location?.hostname) !== -1) ? ((window?.ce?.platform_scorm?.apiReferrer === 'storefront') ? 'https://learner.contentenablers.com/storefront/api/playerv2/':'https://learner.contentenablers.com/celms/api/playerv2/') : ( (window.ce?.platform_scorm?.apiReferrer === 'storefront') ? 'https://lms.contentenablers.com/storefront/api/playerv2/' : `https://lms.contentenablers.com/${window.ce?.platform_scorm?.apiReferrer}/api/playerv2/`)},
    
    get S3ASSETSPATH(){return (this.STG_HOSTNAMES.indexOf(window.location.hostname) !== -1) ? "https://dfd4tx2w69ubk.cloudfront.net" : "https://d1oukasrldq7xd.cloudfront.net"  },
    get S3DOCSPATH(){return (this.STG_HOSTNAMES.indexOf(window.location.hostname) !== -1) ? 'https://s3.us-east-1.amazonaws.com/thumbs.contentenablers.com/assetslib/docs/' : 'https://s3.us-east-1.amazonaws.com/library.contentenablers.com/ceauthor/docs/'},
    get SOCKET_PATH(){return (this.STG_HOSTNAMES.indexOf(window.location.hostname) !== -1) ? 'https://tradecompliance.training' : 'https://beta-cached.contentenablers.com'},   
   
    // API_DESTINATION_STAGING : 'https://tradecompliance.training/xapice/api/',
    // API_SCO_DESTINATION : 'https://learner.contentenablers.com/storefront/api/playerv2/',
    TEMPLATE_IMG_PATH :'https://s3.us-east-1.amazonaws.com/library.contentenablers.com/ceauthor/',
    CC_TXT_PATH : '//d14rwvged4djyq.cloudfront.net/type3/',
    /* LEGACY_INTERACTIVITY CRS */
    LEGACY_INTERACTIVITY:'63e1e5b925f4ef1348007635',
    POWERED_BY_CE:"https://assets.contentenablers.com/storefront/imgs/powered_by_v2.png",
    /**
     * You may get the AUTH CODE from 
     * https://learner.contentenablers.com/celms/secureLogin
     * karthik@contentenablers.com / 111111
     */
    LMS_URL:"https://lms.contentenablers.com/",
    STOREFRONT_URL:"https://contentenablers.net/",
    UPDATE_I18N_COURSE_CHANGE:'https://appproxy.contentenablers.com/curator/index.php/api/lmscoursemap/updatecelmscrsid',
    VERSION_APPROXY:"https://appproxy.contentenablers.com/curator/index.php/api/courses/updateplayerversion",
    AML_PROCESS_EMAIL_STAG:"https://appproxy.contentenablers.com/curator/index.php/api/lmscoursemap/coursecompletionemail",
    // AML_PROCESS_EMAIL:"https://curator.contentenablers.com/index.php/api/lmscoursemap/coursecompletionemail",
    AML_PROCESS_EMAIL:"https://storefront.contentenablers.com/storefront2020/index.php/api/lmscoursemap/coursecompletionemail",
    API_SAVE_LEARNING : 'saveLearningInfoRLM',
    API_GET_AUTH : 'auth',
    VIDEO_M3U8_PATH:"https://d37bn5iomyf0su.cloudfront.net/manager/",
    AUTH_CODE : 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNjM5OjE1MDprYXJ0aGlrQGNvbnRlbnRlbmFibGVycy5jb206a2FydGhpa0Bjb250ZW50ZW5hYmxlcnMuY29tIiwiZXhwIjoxNjM1NTA4Mzk3fQ.G36Z3smPh_Y45xrhQAoToHxPKY7huOKFjCIMSe4SFRkVu8lMk_1tQYJr4GtbPgufrCKlqnR2vlDc0SVm1BwITw',
    API_COURSE_STRUCTURE : 'courses/getcoursestructure?id=',
    I18N_PATH:`${process.env.PUBLIC_URL}/content/translation.json`,
    // this template object is a Hack used to render components until we rename components in API data
    // only for testing purposes
    // FALLBACK_TEMPLATES: {
    //     IntroBar: 'TopicHeader',
    //     SnippetBar: 'TopicDescription',
    //     FlipCard: 'CourseImageFlip',
    //     AnswerSelect: 'RadioSelect',
    //     Chart: 'BarChart',
    //     BulletList : 'OrderedList',
    //     ListSection : 'UnorderedList',
    //     CardFlip : 'CourseCardFlip',
    //     ImageCardText : 'HalfImageText',
    //     QuoteBar: 'TopicHeader',
    //     CardStacker: 'CardStackCarousel',
    //     ImageSelection: 'ImageSelect'
    // },
    
    API_SUCCESS_STR : 'success',
    COMPONENT_CONSTANTS:{
        STATUS_COMPLETE: 'Completed',
        STATUS_INCOMPLETE: 'Incomplete',
        UPDATE_STATUS: 'UPDATE_STATUS',
        SAVE_PROGRESS_STATUS: 'SAVE_PROGRESS_STATUS',
        INSTRUCTIONS_PASSED: 'You have completed this interactivity.',
        COMPLETED_CLASS: 'completed'
    },
    COMPONENTS_LIST : {
        "0":{name:"TopicHeader",id:0, type:"TPHEADER",title:"Topic Header"},
        "1":{name:"Video",id:1,type:"VIDEO",title:"VIDEO"},
        "2":{name:"TopicHeader",id:2,type:"TPHEADER",title:"Topic Header"},
        "3":{name:"Audio",id:3,type:"AUDIO",title:"AUDIO"},
        "4":{name:"TopicDescription",id:4,type:"TPDESC",title:"Topic Description"},
        "5":{name:"CourseImageFlip",id:5,type:"CAFIMG", max_count:6,title:"Course Image Flip"},
        "6":{name:"Tabs",id:6 ,type:"TABS", max_count: 5,title:"Tabs"},
        "7":{name:"HalfImageText",id:7,type:"HIMGTEXT", max_count: 3,title:"Half Image Text"},
        "8":{name:"CourseCardFlip",id:8,type:"COCAF", max_count:6,title:"Course Card Flip"},
        "9":{name:"DragnDrop",id:9,type:"DND",title:"Drag & Drop"},
        "10":{name:"ImageMap",id:10,type:"IMGMAP",title:"ImageMap"},
        "11":{name:"Accordion",id:11,type:"ACC", max_count: 30,title:"Accordion"},
        "12":{name:"Carousels",id:12,type:"CAR",title:"Carousels"},
        "24":{name:"CardStackCarousel",id:24,type:"CARDSTACK",title:"Card Stack Carousel"},
        "14":{name:"ImageText",id:14,type:"IMGTEXT", max_count: 3,title:"Image & Text"},
        "15":{name:"RadioSelect",id:15,type:"RADIOSEL",title:"Radio Selection"},
        "16":{name:"OrderedList",id:16,type:"ORDL",title:"Ordered List"},
        "17":{name:"UnorderedList",id:17,type:"UORDL",title:"Unordered List"},
        "18":{name:"ImageSelect",id:18,type:"IMGSEL",title:"Image Selection"},
        // "19":{name:"BarChart",id:19,type:"BARCHART",title:"Chart"},
        "20":{name:"ArchiveVideo",id:20,type:"ARCHIVE",title:"Archive Video"},
        "21":{name:"BackgroundImage",id:21,type:"BGIMG",max_count:5,title:"Background Image"},
        "25":{name:"Attachments",id:25,type:"ATTACH",title:"Attachments"},
        "26":{name:"KnowledgeCheck",id:26,type:"KNOWLEDGECHECK",title:"KnowledgeCheck"},
        "27":{name:"Quizzes",id:27,type:"QUIZZES",title:"QUIZZES"},
        "28":{name:"ImageMapper",id:28,type:"IMGHIGHLIGHT",max_count:5,title:"ImageMapper"},
        "29":{name:"Draggables",id:29,type:"DRAG",max_count:4,title:"Draggables"},
        "30":{name:"Quotes", id:30, type:"Quote", title:"Quotes"},
        "31":{name:"ScenarioBlock", id:31, type:"Scenario", title:"ScenarioBlock"},
        "32":{name:"DragandMatch", id:32, type:"Match", title:"DragandMatch"},
        "33":{name:"ReorderingList", id:33, type:"shuffle", title:"ReorderingList"},
        "34":{name:"ImageGallery", id:34, max_count:15, type:"gallery", title:"ImageGallery"},
        "35":{name:"DocumentViewer", id:35, type:"DocView", title:"DocumentViewer"},  
        "36":{name:"VidExplo", id:36, type:"DocView", title:"VidExplo"},
        "37":{name:"RoadRunner", id:37, type:"DocView", title:"RoadRunner"}
      },
      //Assets Using Components-image/video/audio
      ASSETS_USED_COM :[
        'Video','Audio','CourseImageFlip',
        'Tabs','HalfImageText','CourseCardFlip','Draggables',
        'DragnDrop','ImageMap','Accordion',
        'Carousels','CardStackCarousel','ImageText',
        'ImageSelect','ArchiveVideo','BackgroundImage',
        ,'ImageMapper','Quizzes','Quotes',"ScenarioBlock","KnowledgeCheck","ImageGallery","VidExplo"
      ],
      CONFIG_UNUSED_ASSETS:['video'],
      IMAGESTYLES:['BackgroundImage','CardStackCarousel','Quotes',
      'CourseCardFlip','CourseImageFlip','ImageGallery',
      'ScenarioBlock','HalfImageText','Tabs',
      'ImageSelect',  'Carousels','Accordion' ],
      IMAGEDELETE:['BackgroundImage','ArchiveVideo','Video','Accordion','Tabs','Draggables','Quotes','KnowledgeCheck','ScenarioBlock','DocumentViewer' ],
       /* content panel this all are having 40% panel width */
       CONTENT_PANELS :[
        "Accordion","TopicHeader","TopicDescription",
        "BackgroundImage","Draggables","DragandMatch","ReorderingList",
       ],
       BIGGER_CONTENT_PANELS:["Quizzes","ScenarioBlock","KnowledgeCheck","RoadRunner"],
       TEMPLATE_STYLES:["TopicHeader","ScenarioBlock"],
       QUICK_AC_SAVE:["ImageMapper","TopicHeader","ScenarioBlock"],
       ARCHIVE_VIDEO_ENABLE_LIST:['kim@contentenablers.com','kim@ceauthor.com'],
       CERTIFICATE_ENABLE_LIST:['kara@contentenablers.com','kara@ceauthor.com'],
       SCROLL_COMPLETION_LRN:[
        "BackgroundImage","TopicHeader","TopicDescription",'ImageText',
        'HalfImageText',"ImageGallery"
       ],
       LRN_RE_NAME: {
        ImageGallery:'Image Gallery',
        BackgroundImage: 'Masthead',
        CardStackCarousel: 'Carousel - Card',
        Carousels: 'Carousel- Image/Text',
        CourseCardFlip: 'Flip- Image and Text',
        CourseImageFlip: 'Flip- Image',
        DocumentViewer: 'Doc - Viewer',
        DragandMatch: 'Drag & Match - Text',
        Draggables: 'Drag & Match - Image',
        HalfImageText: 'Half Image Text',
        ImageSelect: 'Knowledge Check - Image',
        Quizzes: 'Quiz',
        KnowledgeCheck:'Knowledge Check',
        ScenarioBlock:'Scenario Block',
        ReorderingList: 'Drag Order List',
        TopicDescription: 'Text',
        TopicHeader: 'Call to Action',
        RadioSelect:'Radio Select',
        ImageMapper:'Image Mapper'
      },
      COMP_ICONS: {
       TopicHeader:'topic_header.png',
       Accordion:'Accordion_comp.png',
       Audio:'Audio_comp.png',
       Attachments:'topic_header.png',
       CardStackCarousel:'Course_Image_Flip.svg',
       Carousels:'Carousel_comp.png',
       DocumentViewer:"Image_text.png",
       Draggables:'drag_and_match.png',
       DragandMatch:'Draggables_comp.png',
       ReorderingList:'Draggables_comp.png',
       CourseImageFlip:'Flip_card.svg',
       CourseCardFlip:'Course_Image_Flip.svg',
       HalfImageText:"Image_text.png",
       ImageGallery:'ImageSelect_comp.png',
       ImageMapper:'Imagemapping_comp.png',
       KnowledgeCheck:'Quizzes_comp_.png',
       ImageSelect:'Quizzes_comp_.png',
       BackgroundImage:'Carousel_comp.png',
       Quizzes:'Quizzes_comp_.png',
       Quotes:'Quotes.svg',
       RadioSelect:'Quizzes_comp_.png',
       ScenarioBlock:'Scenario.svg',
       Search:'search.png',
       Tabs:'Tab_comp.png',
       TopicDescription:'Topicdesc_comp.png',
       Video:'Video_comp.png',
       ArchiveVideo:'Video_comp.png',
     },
      
      COMPONENTS_STATE_LIST : {
        s:0, st :{}
      },

      getComponentByName : (compName)=>{
        // console.debug("Comp name======",compName)
        for(let i in PlayerConstants.COMPONENTS_LIST) {
          if(PlayerConstants.COMPONENTS_LIST[i].name === compName)  {
            return PlayerConstants.COMPONENTS_LIST[i];
          }
        }
        return -1;
      },
   
    RTL_LANGUAGES:['he_HE','ar_BH','ar_MA'],
    SIMPLE_TOPIC_CONTENT : [
        {
            id: 4,
            name: "FlipCard",
            "props": {
                "items": [
                    {
                        id: 1,
                        "frontImage": "R9FxiGNlD9UKdiSI_iRv9EJQPns_fRWCl.png",
                        "backImage": "https://img.buzzfeed.com/buzzfeed-static/static/2014-04/enhanced/webdr06/4/16/enhanced-11136-1396643149-13.jpg?no-auto",
                        "flipBtnText": "click to flip",
                        "description": "You learn that your product engineer, Alex, is a dual national of the UK and Russia.",
                        "flipped": false
                    },
                    {
                        id: 2,
                        "frontImage": "0_w5bmENuvTefJfq_vAbcnuCkPZl3CEIC.png",
                        "backImage": "https://img.buzzfeed.com/buzzfeed-static/static/2014-04/enhanced/webdr06/4/16/enhanced-11136-1396643149-13.jpg?no-auto",
                        "flipBtnText": "click to flip",
                        "description": "Your quality control manager, Anna, is a national of Poland",
                        "flipped": false
                    },
                    {
                        id: 3,
                        "frontImage": "YrtXVKi3wBH2vIbl_DPdNcPeIo1Gor6NL.png",
                        "backImage": "https://img.buzzfeed.com/buzzfeed-static/static/2014-04/enhanced/webdr06/4/16/enhanced-11136-1396643149-13.jpg?no-auto",
                        "flipBtnText": "click to flip",
                        "description": "And you are later told that your third-country supplier has a design engineer, Ben, who is a dual national of France and China. This poses a challenge in light of your planned activities – reexporting the EAR-controlled technology to your third-country supplier and retransferring it to your in-country supplier – because it means that your third-country supplier would subsequently release the controlled technology to Ben.",
                        "flipped": false
                    }
                ]
            }
        },
        {
            id: 7,
            name: "Audio",
            "props": {
                "src": "PaVFYPsr7k5rOzLF_transcoded-5td-ZzgOkXq2KnFy-CS1_MidText.mp3"
            }
        }
    ],

    TOPIC_STATUS:{"-1":"non-visited","0":'visited',"1":'completed'},
    
    STATUS_LRN_AIDS : { 
        '-1' : { id: -1, status: "Not-started" },
        '1' : { id: 1, status: "Approved" },
        '2' : { id: 2, status: "In-Progress" },
        '3' : { id: 3, status: "Under review" },
    },

    STATUS_COMMENTS : { 
        '-4' : { id: -4, status: "AAAA" },
        '-3' : { id: -3, status: "BBBB" },
        '-2' : { id: -2, status: "CCCC" },
        '-1' : { id: -1, status: "DDDD" },
        '0' : { id: 0, status: "EEE" },
        '1' : { id: 1, status: "FFFF" },
        '2' : { id: 2, status: "ZZZZZ" },
    },   

    COMMENTS_STATUS:{
        '7' : { id: [7,-1,-2,-3,1], status: "Resolved", color:"#16994d" },
        '5' : { id: [5], status: "Active", color:"#002873" },
        '6' : { id: [6], status: "ToDo", color:"#fe093c" },
    },

    CE_ASSETS:{
        '1':{id:1,type:'image'},
        '2':{id:2,type:'video'},
        '3':{id:3,type:'audio'},
        '5':{id:5,type:'gif'},
    },
    INS:{
        COMPLETED: 'Completed',
        INCOMPLETE: 'Incomplete',
        UPDATE_STATUS: 'UPDATE_STATUS',
        SAVE_PROGRESS_STATUS: 'SAVE_PROGRESS_STATUS',
        PASSED: 'You have completed this interactivity, you can proceed to next section',
    },
    palette:{
        ce_theme:{
            font:'RobotoRegular',
            styles:{
            '--primary-color-1': '#13984B', /* first primary color */
            '--primary-color-2': '#f3c465', /* second primary color */
            '--secondary-color-1': '#4f5759', /* first secondary color */
                '--secondary-color-2': '#000', /* Second secondary color */
                '--primary-radius-1': '.3rem', /* first primary radius */
                '--scrollbar-primary-1':'#13984B',
                '--completion-color': '#13984B',  /* completed status color */
                '--visited-color': '#f3c465',     /* visited status color*/
                '--non-visited-color':' #4f5759',/* non-visited status color*/
            }
        },
        skill_theme:{ 
            font:'BitterRegular|GilroyMedium',
            styles:{
            '--primary-color-1': '#052269',
            '--primary-color-2': '#ff0a3c', 
            '--secondary-color-1': '#ff0a3c',
            '--secondary-color-2': '#ffb900',
            '--scrollbar-primary-1':'#9d9d9c',
            '--primary-radius-1': '3rem',
            '--completion-color': '#ffb900', 
            '--visited-color': '#ff0a3c',   
            '--non-visited-color':' #001450'
          }}
    }
}

export default PlayerConstants;
 

