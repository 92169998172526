import React from 'react'
import Video from '../Video/Video'


const VidExplo = ({ src, items,poster}) => {
  return (
    <div className='videxplo-container'>
      <Video src={src} poster={poster}/>
    </div>
  )
}

export default VidExplo